<template>
  <Card class="my-4 mx-6 py-2 px-4">
    <template #title>
      <div class="grid nogutter">
        <!-- Offering Title -->

        <div
          class="col-12 md:col-fixed text-center md:text-left logo-fixed-width"
        >
          <img class="offering-img" :src="offeringImage" />
        </div>
        <div class="col ml-3" style="margin: auto">
          <div class="grid nogutter flex-column">
            <div class="col offering-title text-center md:text-left">
              {{ offeringTitle }}
            </div>
            <div class="col offering-type text-center md:text-left">
              {{ offeringType }}
            </div>
          </div>
        </div>
        <!-- End Offering Title -->
      </div>
    </template>
    <template #content>
      <div class="grid nogutter flex-column">
        <!-- Offering Information -->
        <div class="col mb-2 offering-info-title">Offering Information</div>
        <!-- End Offering Information -->

        <!-- Minimum Investment Amount -->
        <div class="col mb-2">
          <div class="grid nogutter">
            <div class="col-12 sm:col-5 offering-info">
              Minimum Investment Amount
            </div>
            <div class="col">{{ minInvestmentAmount }}</div>
          </div>
        </div>
        <!-- End Minimum Investment Amount -->

        <!-- Maximum Investment Amount -->
        <div class="col mb-2">
          <div class="grid nogutter">
            <div class="col-12 sm:col-5 offering-info">
              Maximum Investment Amount
            </div>
            <div class="col">{{ maxInvestmentAmount }}</div>
          </div>
        </div>
        <!-- End Maximum Investment Amount -->

        <!-- Share Price -->
        <div class="col mb-2">
          <div class="grid nogutter">
            <div class="col-12 sm:col-5 offering-info">Share Price</div>
            <div class="col">{{ sharePrice }}</div>
          </div>
        </div>
        <!-- End Share Price -->

        <!-- Offering Documents -->
        <div class="col mt-2">
          <div class="grid nogutter">
            <div class="col-12 md:col-4 offering-info-title">
              Offering Documents
            </div>
            <div class="col md:text-right">
              <Button
                class="mr-3 no-box-shadow v1-button"
                label="Download All"
                @click="downloadAllDocs"
              />
              <Button
                class="no-box-shadow v1-button"
                :label="showDocsButtonLabel"
                @click="toggleShowDocs"
              />
            </div>
          </div>
        </div>

        <div
          v-if="isShowingDocs && offeringDocuments.length > 0"
          class="col mt-2"
        >
          <div class="grid nogutter">
            <div
              v-for="(doc, index) in offeringDocuments"
              :key="index"
              class="col-6 md:col-3"
            >
              <div class="grid nogutter flex-column">
                <div class="col mb-1">
                  <span class="doc-title">{{ doc.type_display }}</span>
                </div>
                <div class="col">
                  <Button
                    class="mr-2 no-box-shadow v1-button"
                    label="Download"
                    @click="downloadDocument(doc)"
                  />
                  <Button
                    class="no-box-shadow v1-button"
                    label="View"
                    @click="showDocument(doc)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="isShowingDocs && offeringDocuments.length === 0"
          class="col mt-3"
        >
          No offering documents have been uploaded
        </div>
        <!-- End Offering Documents -->
      </div>

      <FileViewDialog
        :display="fileViewModal"
        :filePath="fileViewPath"
        @ok="closeFileViewModal"
      />
    </template>
  </Card>
</template>

<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  inject,
  onMounted,
  ref,
  PropType,
  computed,
} from "vue";

import {
  MakeInvestmentResponse,
  Document,
} from "@/interfaces/v1/MakeInvestmentResponse";
import { OfferingCompany } from "@/interfaces/v1/offeringCompany";
import {
  PostIcon,
  PostFileDownload,
  PostAllFilesDownload,
} from "@/api/services/v1Payloads";
import { Api } from "@/api";
import { Formatter } from "@/utils/formatter";

const FileViewDialog = defineAsyncComponent(
  () => import("@/components/v1/dialog/FileViewDialog.vue")
);

export default defineComponent({
  name: "InvestNowInvestmentFormOfferingInfo",
  components: {
    FileViewDialog,
  },
  props: {
    investmentData: {
      type: Object as PropType<MakeInvestmentResponse>,
      required: true,
    },
    offeringCompany: {
      type: Object as PropType<OfferingCompany>,
      required: true,
    },
  },
  setup(props) {
    const $api = inject("$api") as Api;

    const COMPANY_LOGO_ID = "49";

    // refs
    const offeringImage = ref();
    const isShowingDocs = ref(false);

    const fileViewModal = ref(false);
    const fileViewPath = ref("");

    // computed
    const offeringTitle = computed(() => {
      if (props.investmentData?.offering?.offering_title) {
        return props.investmentData.offering.offering_title;
      }

      return "-";
    });

    const offeringType = computed(() => {
      if (
        props.investmentData?.offering?.offering_regulation_type &&
        props.investmentData?.offering?.offering_financing_type
      ) {
        return `${props.investmentData.offering.offering_regulation_type} | ${props.investmentData.offering.offering_financing_type}`;
      } else if (props.investmentData?.offering?.offering_regulation_type) {
        return props.investmentData.offering.offering_regulation_type;
      } else if (props.investmentData?.offering?.offering_financing_type) {
        return props.investmentData.offering.offering_financing_type;
      }

      return "-";
    });

    const minInvestmentAmount = computed(() => {
      if (props.investmentData?.offering?.minimum_investment) {
        return Formatter.currency(
          props.investmentData.offering.minimum_investment
        );
      }

      return "Not Available";
    });

    const maxInvestmentAmount = computed(() => {
      if (props.investmentData?.offering?.maximum_investment) {
        return Formatter.currency(
          props.investmentData.offering.maximum_investment
        );
      }

      return "Not Available";
    });

    const sharePrice = computed(() => {
      if (props.investmentData?.offering?.share_price) {
        return Formatter.currency(props.investmentData.offering.share_price);
      }

      return "Not Available";
    });

    const offeringDocuments = computed(() => {
      console.log("offeringDocuments");
      let documents = [] as Document[];
      if (props.investmentData?.offering?.documents["offering"]) {
        const offeringDocs =
          props.investmentData.offering.documents["offering"];
        for (const key in offeringDocs) {
          if (key !== COMPANY_LOGO_ID) {
            const doc = offeringDocs[key];
            if (doc.uploaded.length > 0) {
              documents.push(doc);
            }
          }
        }
      }

      return documents;
    });

    const showDocsButtonLabel = computed(() => {
      if (isShowingDocs.value) {
        return "Hide All";
      }

      return "Show All";
    });

    // private methods
    const loadOfferingLogo = async () => {
      if (props.offeringCompany?.file_path) {
        let req: PostIcon = {
          file_path: props.offeringCompany.file_path as string,
        };
        let { data } = await $api.v1.getOfferingHeader(req);
        // offeringImage.value = window.URL.createObjectURL(data);
        console.log(data);

        const reader = new FileReader();
        const blob = new Blob([data], { type: "image/*" });
        reader.onload = () => {
          console.log("GOt here...");
          offeringImage.value = reader.result;
        };
        reader.readAsDataURL(blob);
      }
    };

    // ui methods
    const downloadAllDocs = async () => {
      console.log("downloadAllDocs");

      try {
        const req: PostAllFilesDownload = {
          user_id: props.investmentData.investors[0].user_id,
          id: props.investmentData.investment.offering_id,
        };

        const { data }: { data: Blob } = await $api.v1.getAllFiles(req);

        console.log(data);
        const blobURL = window.URL.createObjectURL(data);
        const downloadLink = document.createElement("a");
        downloadLink.style.display = "none";
        downloadLink.href = blobURL;
        downloadLink.setAttribute("download", "documents.zip");
        if (typeof downloadLink.download === "undefined") {
          downloadLink.setAttribute("target", "_blank");
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setTimeout(() => {
          window.URL.revokeObjectURL(blobURL);
        }, 100);
        console.log(req);
      } catch (e) {
        console.error(e);
      }
    };

    const toggleShowDocs = () => {
      isShowingDocs.value = !isShowingDocs.value;
    };

    const downloadDocument = async (doc: Document) => {
      console.log(doc.uploaded);

      if (doc.uploaded.length > 0) {
        try {
          const req: PostFileDownload = {
            file_path: doc.uploaded[0].file_path,
          };

          const { data }: { data: Blob } = await $api.v1.getFile(req);

          console.log(data);
          const blobURL = window.URL.createObjectURL(data);
          const downloadLink = document.createElement("a");
          downloadLink.style.display = "none";
          downloadLink.href = blobURL;
          downloadLink.setAttribute("download", doc.uploaded[0].file_name);
          if (typeof downloadLink.download === "undefined") {
            downloadLink.setAttribute("target", "_blank");
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          setTimeout(() => {
            window.URL.revokeObjectURL(blobURL);
          }, 100);
          console.log(req);
        } catch (e) {
          console.error(e);
        }
      }
    };

    const showDocument = (doc: Document) => {
      console.log(doc);
      if (doc.uploaded.length > 0) {
        fileViewPath.value = doc.uploaded[0].file_path;
        console.log(fileViewPath.value);
        fileViewModal.value = true;
      }
    };

    const closeFileViewModal = () => {
      fileViewPath.value = "";
      fileViewModal.value = false;
    };

    // lifecycle hooks
    onMounted(() => {
      loadOfferingLogo();
    });

    return {
      // refs
      offeringImage,
      isShowingDocs,
      fileViewModal,
      fileViewPath,
      // computed
      offeringTitle,
      offeringType,
      minInvestmentAmount,
      maxInvestmentAmount,
      sharePrice,
      offeringDocuments,
      showDocsButtonLabel,
      // methods
      downloadAllDocs,
      toggleShowDocs,
      downloadDocument,
      showDocument,
      closeFileViewModal,
    };
  },
});
</script>

<style lang="scss" scoped>
.offering-img {
  max-width: 160px;
  max-height: 120px;
  mix-blend-mode: multiply;
}

.offering-title {
  font-size: 24px;
  font-weight: normal;
}

.offering-type {
  font-size: 16px;
  font-weight: normal;
}

.offering-info-title {
  font-size: 18px;
  font-weight: normal;
}

.offering-info {
  font-weight: bold;
}

.doc-title {
  font-weight: bold;
}

@media (min-width: 576px) {
  .logo-fixed-width {
    width: 160px;
  }
}
</style>
