export class Formatter {
  static currency(amount: number): string {
    const dollars = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      useGrouping: true,
      minimumFractionDigits: 2,
    });

    return dollars.format(amount);
  }

  static capitalizeString(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
