<template>
  <div class="grid nogutter flex-column">
    <!-- Header -->
    <div class="col py-3 px-4 header" style="margin: auto">
      Accreditation Verification
    </div>
    <!-- End Header -->

    <!-- Accreditation Container -->
    <div class="col px-4 border-container">
      <!-- Accreditation Status -->
      <div v-if="hasAccreditationStatus" class="grid nogutter flex-column">
        <!-- Status -->
        <div class="col nogutter mt-3 mb-2">
          <div class="grid nogutter">
            <div class="col-6 md:col-4">Accreditation Status</div>
            <div class="col">
              {{ accreditationStatus }}
            </div>
          </div>
        </div>
        <!-- End Status -->

        <!-- Max Amount -->
        <div v-if="!isAccredited" class="col nogutter mb-2">
          <div class="grid nogutter">
            <div class="col-4">Maximum Investment Amount</div>
            <div class="col">
              {{ maxInvestmentAmount }}
            </div>
          </div>
        </div>
        <!-- End Max Amount -->
      </div>
      <!-- End Accreditation Status Status -->

      <!-- Accreditation Form -->
      <div v-if="!hasAccreditationStatus" class="grid nogutter flex-column">
        <!-- Accreditation Explanation -->
        <div class="col nogutter mt-3 mb-2">
          You must be verified as accredited via your income or assets to invest
          in a Regulation D offering.<br />
          If you choose income, you must have earned income that exceeded
          $200,000 (or $300,000 together with a spouse) in each of the prior two
          years, and reasonably expects the same for the current year. You will
          need to sign the 4506T form in the Document Signature section and give
          us permission to pull your income information from the IRS.<br />
          If you choose assets, you must have a net worth over $1 million,
          either alone or together with a spouse (excluding the value of the
          person’s primary residence). you'll need to provide documentation
          proving the worth of your assets and give us permission to pull your
          credit report.<br />
          If you choose Verified, you will need to provide documentation of
          accreditation from a lawyer, CPA, RIA or a Third-Party Accreditation
          Verification Provider.<br />
        </div>
        <!-- End Accreditation Explanation -->

        <!-- Accreditation Status -->
        <div class="col nogutter">
          <div class="grid nogutter">
            <div class="col-6 md:col-4">Accreditation Status</div>
            <div class="col pl-0">
              {{ accreditationStatus }}
            </div>
          </div>
        </div>
        <!-- End Accreditation Status -->

        <!-- Accreditation Process -->
        <div class="col nogutter my-2">
          <div class="grid nogutter">
            <div class="col-6 lg:col-4 pt-2">Accreditation Process</div>
            <div
              v-if="hasAccreditationProcessStatus"
              class="col-4 pl-0 v1-input-container"
            >
              {{ accreditationProcess }}
            </div>
            <div v-else class="col-6 lg:col-4 pl-0 v1-input-container">
              <Dropdown
                id="processDropdown"
                v-model="process"
                class="input-selector"
                appendTo="self"
                :options="processOptions"
              />
            </div>
            <div class="col-12 lg:col-4 px-2 lg:px-4">
              <!-- Income Verified -->
              <div id="incomeCheckbox" v-if="process === 'Income'">
                <Checkbox id="income" v-model="incomeAllowed" :binary="true" />
                <label for="income" class="pl-2">
                  I allow Capital2Market to request my income information from
                  the IRS.
                </label>
              </div>
              <!-- End Income Verified -->

              <!-- Assets Verified -->
              <div v-if="process === 'Assets'">
                You understand that by clicking on the I AGREE button
                immediately following this notice, you are providing 'written
                instructions' to Capital2Market under the Fair Credit Reporting
                Act authorizing Capital2Market to obtain information from your
                personal credit report or other information from Experian. You
                authorize Capital2Market to obtain such information solely to
                verify you as an accredited investor.<br />
                <Checkbox
                  id="assets"
                  v-model="assetsAllowed"
                  class="mt-3"
                  :binary="true"
                />
                <label for="assets" class="pl-2"> I Agree </label>
              </div>
              <!-- End Assets Verified -->

              <!-- Verified Verified -->
              <div v-if="process === 'Verified'">
                <Checkbox
                  id="verified"
                  v-model="verifiedAllowed"
                  :binary="true"
                />
                <label for="verified" class="pl-2">
                  I verify that the provided document is a legal document from
                  my CPA, Financial Adviser, or Lawyer signifying that I am an
                  accredited investor.
                </label>
              </div>
              <!-- End Verified Verified -->
            </div>

            <!-- Assets Upload -->
            <div
              v-if="process === 'Assets' || process === 'Verified'"
              class="col nogutter mt-4"
            >
              <div class="grid nogutter">
                <div v-if="process === 'Assets'" class="col-6 md:col-4">
                  <div>Proof of Assets</div>
                  <div>Report from CPA or Lawyer</div>
                </div>
                <div v-if="process === 'Verified'" class="col-6 md:col-4">
                  <div>Proof of Accreditation</div>
                  <div>Verification from CPA, Financial Adviser, or Lawyer</div>
                </div>
                <div
                  class="col-6 md:col-4 text-right"
                  style="place-items: center"
                >
                  <span v-if="hasAccreditationDocs">
                    {{ accreditationDocsStat }}
                  </span>
                  <Button
                    class="ml-3 no-box-shadow v1-button"
                    label="Upload File"
                    @click="uploadFileClick"
                  />
                </div>
                <div class="col- md:col-4"></div>
              </div>
            </div>
            <!-- End Assets Upload -->
          </div>
        </div>
        <!-- End Accreditation Process -->
      </div>
      <!-- End Accreditation Form -->
    </div>
    <!-- End Accreditation Container -->

    <div v-if="fileUploadDialog" class="file-upload-dialog">
      <FileUploadDialog
        :title="fileUploadDialogTitle"
        :display="fileUploadDialog"
        :config="fileUploadConfig"
        @success="uploadFileComplete"
        @removed="fileRemoved"
        @cancel="uploadFileCancel"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import { MakeInvestmentResponse } from "@/interfaces/v1/MakeInvestmentResponse";
import { Formatter } from "@/utils/formatter";

import FileUploadDialog, {
  FileUploadConfig,
} from "@/components/v1/dialog/FileUploadDialog.vue";
import { V1ResponseWrapper } from "@/api/services/v1Payloads";

export interface InvestNowInvestmentFormAccreditationVerificationInterface {
  verificationType(): string;
  isValid(): boolean;
}

export default defineComponent({
  name: "InvestNowInvestmentFormAccreditationVerification",
  components: {
    FileUploadDialog,
  },
  props: {
    investmentData: {
      type: Object as PropType<MakeInvestmentResponse>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const DocumentEndpoint = "/document/upload-accreditation-document";
    const DocumentAssetsTypeId = "45";
    const DocumentVerifiedTypeId = "50";

    // refs
    const process = ref<string>();
    const incomeAllowed = ref(false);
    const assetsAllowed = ref(false);
    const verifiedAllowed = ref(false);

    const fileUploadName = ref("");
    const fileUploadDialog = ref(false);
    const fileUploadConfig = ref<FileUploadConfig>({
      endpoint: DocumentEndpoint,
      documentId: DocumentAssetsTypeId,
      ownerId: "",
      accountId: "",
      existingFiles: [],
    });

    // computed
    const hasAccreditationStatus = computed(() => {
      // check if investor is not accredited
      if (
        props.investmentData?.investors &&
        props.investmentData?.investors?.length > 0
      ) {
        const investor = props.investmentData.investors[0];
        if (!investor.investor_accredited) {
          return true;
        } else {
          if (props.investmentData?.accreditation?.status) {
            return props.investmentData.accreditation.status === "accepted";
          }

          return false;
        }
      }

      return true;
    });

    const isAccredited = computed(() => {
      if (
        props.investmentData?.investors &&
        props.investmentData?.investors?.length > 0
      ) {
        const investor = props.investmentData.investors[0];
        return investor.investor_accredited;
      }

      return false;
    });

    const accreditationStatus = computed(() => {
      if (
        props.investmentData?.investors &&
        props.investmentData?.investors?.length > 0
      ) {
        const investor = props.investmentData.investors[0];

        // non-accredited logic
        if (!investor.investor_accredited) {
          return "Non-Accredited";
        } else {
          if (props.investmentData?.accreditation?.status) {
            return Formatter.capitalizeString(
              props.investmentData.accreditation.status
            );
          }

          return "Unverified";
        }
      }

      return "Not Available";
    });

    const hasAccreditationDocs = computed(() => {
      let docId = "";
      if (process.value === "Assets") {
        docId = DocumentAssetsTypeId;
      }

      if (process.value === "Verified") {
        docId = DocumentVerifiedTypeId;
      }

      if (props.investmentData?.documents["accreditation"]) {
        const accreditationDocs =
          props.investmentData.documents["accreditation"];
        console.log(accreditationDocs);
        if (accreditationDocs[docId]) {
          const doc = accreditationDocs[docId];
          return doc.uploaded.length > 0;
        }
      }

      return false;
    });

    const accreditationDocsStat = computed(() => {
      let docId = "";
      if (process.value === "Assets") {
        docId = DocumentAssetsTypeId;
      }

      if (process.value === "Verified") {
        docId = DocumentVerifiedTypeId;
      }

      if (props.investmentData?.documents["accreditation"]) {
        const accreditationDocs =
          props.investmentData.documents["accreditation"];
        console.log(accreditationDocs);
        if (accreditationDocs[docId]) {
          const doc = accreditationDocs[docId];
          return `${doc.uploaded.length} document(s) have been uploaded`;
        }
      }

      return "Documents uploaded";
    });

    const hasAccreditationProcessStatus = computed(() => {
      return !!props.investmentData?.accreditation?.status;
    });

    const accreditationProcess = computed(() => {
      if (props.investmentData?.accreditation?.type) {
        return Formatter.capitalizeString(
          props.investmentData.accreditation.type
        );
      }

      return "Not Available";
    });

    const maxInvestmentAmount = computed(() => {
      if (
        props.investmentData?.investors &&
        props.investmentData?.investors?.length > 0
      ) {
        const investor = props.investmentData.investors[0];

        // non-accredited logic
        if (!investor.investor_accredited) {
          return Formatter.currency(investor.investor_limit);
        } else {
          // todo: accreditation logic
          return "Unlimited";
        }
      }

      return "Not Available";
    });

    const processOptions = computed(() => {
      let processes = [];

      if (props.investmentData?.offering.accreditation_income) {
        processes.push("Income");
      }

      if (props.investmentData?.offering.accreditation_assets) {
        processes.push("Assets");
      }

      if (props.investmentData?.offering.accreditation_verified) {
        processes.push("Verified");
      }

      return processes;
    });

    const fileUploadDialogTitle = computed(() => {
      if (process.value === "Assets") {
        return "Proof of Assets";
      }

      if (process.value === "Verified") {
        return "Proof of Accreditation";
      }

      return "Upload File";
    });

    // ui methods
    const uploadFileClick = () => {
      console.log("upload file");

      // set the correct document id
      if (process.value === "Assets") {
        fileUploadConfig.value.documentId = DocumentAssetsTypeId;
      } else if (process.value === "Verified") {
        fileUploadConfig.value.documentId = DocumentVerifiedTypeId;
      }

      // setup the existing files
      if (props.investmentData?.documents["accreditation"]) {
        const accreditationDocs =
          props.investmentData.documents["accreditation"];
        console.log(accreditationDocs);
        if (accreditationDocs[fileUploadConfig.value.documentId]) {
          const doc = accreditationDocs[fileUploadConfig.value.documentId];
          console.log(doc.uploaded);
          fileUploadConfig.value.existingFiles = doc.uploaded;
        }
      }

      // set the investor id
      if (
        props.investmentData?.investors &&
        props.investmentData?.investors?.length > 0
      ) {
        const investor = props.investmentData.investors[0];
        fileUploadConfig.value.ownerId = investor.investor_id;
      }

      fileUploadDialog.value = true;
    };

    const uploadFileComplete = (resp: V1ResponseWrapper) => {
      console.log("uploadFileComplete");

      if (resp.data) {
        console.log(resp.data["file_name"]);
        fileUploadName.value = resp.data["file_name"];
      }

      fileUploadDialog.value = false;
      emit("update");
    };

    const fileRemoved = () => {
      fileUploadDialog.value = false;
      emit("update");
    };

    const uploadFileCancel = () => {
      fileUploadDialog.value = false;
    };

    const verificationType = (): string => {
      return process.value ? process.value.toLowerCase() : "";
    };

    const isValid = (): boolean => {
      // todo: check if there are files for this type of accreditation

      if (hasAccreditationProcessStatus.value) {
        return true;
      }

      if (process.value === "Income" && incomeAllowed.value === true) {
        return true;
      } else if (
        process.value === "Assets" &&
        assetsAllowed.value === true &&
        fileUploadName.value !== ""
      ) {
        return true;
      } else if (
        process.value === "Verified" &&
        verifiedAllowed.value === true &&
        fileUploadName.value !== ""
      ) {
        return true;
      }

      return false;
    };

    return {
      // refs
      process,
      incomeAllowed,
      assetsAllowed,
      verifiedAllowed,
      fileUploadName,
      fileUploadDialog,
      fileUploadConfig,
      // computed
      hasAccreditationStatus,
      isAccredited,
      hasAccreditationDocs,
      accreditationDocsStat,
      accreditationStatus,
      hasAccreditationProcessStatus,
      accreditationProcess,
      maxInvestmentAmount,
      processOptions,
      fileUploadDialogTitle,
      // methods
      uploadFileClick,
      uploadFileComplete,
      fileRemoved,
      uploadFileCancel,
      verificationType,
      isValid,
    };
  },
});
</script>

<style lang="scss" scoped>
.header {
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  font-weight: bold;
}

.border-container {
  border: 1px solid #ddd;
  border-top-color: transparent;
}
</style>
