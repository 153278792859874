<template>
  <div class="grid nogutter flex-column">
    <div class="col my-3 text-center">
      <img
        alt="Capital2Market Logo"
        src="@/assets/c2m-logo.png"
        class="make-investment-logo"
      />
    </div>
    <div class="col py-4 px-6 make-investment-title-container">
      <span class="make-investment-title">Make Investment</span>
    </div>

    <div v-if="loading" class="col mt-6 text-center">
      <ProgressSpinner style="width: 100px; height: 100px" strokeWidth="2" />
    </div>
    <div v-if="!loading && !error" class="col">
      <InvestNowInvestmentFormOfferingInfo
        :investment-data="investmentData"
        :offering-company="offeringCompany"
      />
    </div>
    <div v-if="!loading && !error" class="col">
      <InvestNowInvestmentFormInvestmentDetails
        ref="investmentDetailsComponent"
        :investment-data="investmentData"
        @saving="investmentIsSaving"
        @saved="investmentDidSave"
        @filesUpdate="filesDidUpdate"
        @error="formErrorEvent"
      />
    </div>
    <div v-if="!loading && !error" class="col">
      <InvestNowInvestmentDocumentSignature
        id="documentSignComponent"
        ref="documentSignComponent"
        :investment-data="investmentData"
        @initialized="documentSignInitialized"
        @signed="documentSigned"
        @error="formErrorEvent"
      />
    </div>

    <div v-if="finalize" class="col">
      <InvestNowInvestmentCompleteInvestment
        :investment-data="investmentData"
        @complete="completeInvestment"
      />
    </div>

    <!-- error -->
    <div v-if="error" class="col mt-4">
      <div class="grid nogutter flex-column">
        <div class="col text-center">
          <i class="fas fa-exclamation-triangle fa-3x c2m-error-color"></i>
        </div>
        <div class="col text-center error-text">
          There was an error loading this investment. Please contact support if
          you need assistance.
        </div>
      </div>
    </div>

    <Dialog
      header="Accreditation Required"
      v-model:visible="unaccreditedError"
      class="v1-accreditation-error-dialog"
      :modal="true"
    >
      <!--      <p class="m-0">-->
      <!--        The offering requires investors to be accredited. An accredited investor-->
      <!--        is an individual or a business entity that is allowed to trade-->
      <!--        securities that may not be registered with financial authorities. They-->
      <!--        are entitled to this privileged access by satisfying at least one-->
      <!--        requirement regarding their income, net worth, asset size, governance-->
      <!--        status, or professional experience.-->
      <!--      </p>-->
      <!--      <p class="mt-4">-->
      <!--        If you this is an error, please contact support and we can assist in-->
      <!--        resolving the issue.-->
      <!--      </p>-->

      <p class="m-0">
        Regulation D offerings are limited to accredited investors only. The
        information you provided on your profile indicates that you do not meet
        the criteria to be defined as accredited. To be an accredited investor,
        a person must have an annual income exceeding $200,000 ($300,000 for
        joint income) for the last two years with the expectation of earning the
        same or a higher income in the current year. A person is also considered
        an accredited investor if they have a net worth exceeding $1 million,
        either individually or jointly with their spouse. An entity is
        considered an accredited investor if it is a private business
        development company or an organization with assets exceeding $5 million.
        Also, if an entity consists of equity owners who are accredited
        investors, the entity itself is an accredited investor. In 2020, the
        U.S. Congress modified the definition of an accredited investor to
        include registered brokers and investment advisors.
      </p>
      <p class="mt-4">
        If you are an investment advisor or registered broker that does not meet
        the financial criteria to be an accredited investor, please email
        customer support for assistance with your profile at
        <a href="mailto:support@capital2market.com">
          support@capital2market.com
        </a>
      </p>
      <p class="mt-4">
        Regulation D offerings are limited to accredited investors only. If you
        would like to invest as a non-accredited investor, please visit the
        Capital2Market marketplace and choose either a Regulation A+ or
        Regulation CF issuer. Both Regulation A+ and Regulation CF allow for
        non-accredited investments.
      </p>
      <p class="mt-4">Press OK to be taken back to the marketplace.</p>
      <template #footer>
        <Button
          label="Ok"
          class="no-box-shadow v1-button"
          @click="redirectAccreditation"
          autofocus
        />
      </template>
    </Dialog>

    <Dialog
      header="Submit Form Error"
      v-model:visible="formError"
      class="v1-accreditation-error-dialog"
      :modal="true"
    >
      <p class="m-0">
        {{ formErrorText }}
      </p>
      <template #footer>
        <Button
          label="Ok"
          class="no-box-shadow"
          @click="closeFormError"
          autofocus
        />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  inject,
  onMounted,
  ref,
  computed,
  nextTick,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { Api } from "@/api";
import { Dictionary } from "@/interfaces";
import { MutationType } from "@/store/mutations";

import InvestNowInvestmentFormOfferingInfo from "@/components/v1/invest-now-investment-v1/InvestNowInvestmentFormOfferingInfo.vue";
import InvestNowInvestmentFormInvestmentDetails, {
  InvestNowInvestmentFormInvestmentDetailsInterface,
} from "@/components/v1/invest-now-investment-v1/InvestNowInvestmentFormInvestmentDetails.vue";
import InvestNowInvestmentDocumentSignature, {
  InvestNowInvestmentDocumentSignatureInterface,
} from "@/components/v1/invest-now-investment-v1/InvestNowInvestmentDocumentSignature.vue";
import InvestNowInvestmentCompleteInvestment from "@/components/v1/invest-now-investment-v1/InvestNowInvestmentCompleteInvestment.vue";

import {
  GetOfferingCompany,
  PostInvestmentToken,
  V1ResponseWrapper,
} from "@/api/services/v1Payloads";

import { MakeInvestmentResponse } from "@/interfaces/v1/MakeInvestmentResponse";
import { OfferingCompany } from "@/interfaces/v1/offeringCompany";

export default defineComponent({
  name: "InvestNowInvestmentForm",
  components: {
    InvestNowInvestmentFormOfferingInfo,
    InvestNowInvestmentFormInvestmentDetails,
    InvestNowInvestmentDocumentSignature,
    InvestNowInvestmentCompleteInvestment,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const $api = inject("$api") as Api;
    /*
    1) ???
      /investment/auth-token
      {token: TOKEN}
2)
      /investment/validate-token
      {token: TOKEN}
      -- investment, offering,  etc....


      /investment/get-make-data
      {offering_id, }

     */

    const loading = ref(false);
    const error = ref(false);
    const finalize = ref(false);
    const investmentData = ref({} as MakeInvestmentResponse);
    const offeringCompany = ref({} as OfferingCompany);

    const investmentDetailsComponent =
      ref<InvestNowInvestmentFormInvestmentDetailsInterface | null>(null);
    const documentSignComponent =
      ref<InvestNowInvestmentDocumentSignatureInterface | null>(null);

    let token = "";

    const unaccreditedError = computed(() => {
      // inline if with this is throwing an always false error???
      const numInvestors = investmentData.value.investors
        ? investmentData.value.investors.length
        : 0;
      if (numInvestors === 0) {
        return false;
      }

      return !!(
        (investmentData.value.offering?.accreditation_income ||
          investmentData.value.offering?.accreditation_assets ||
          investmentData.value.offering?.accreditation_verified) &&
        !investmentData.value.investors[0].investor_accredited
      );
    });

    const formError = ref(false);
    const formErrorText = ref("");

    const checkInvestmentStatus = () => {
      console.log("checkInvestmentStatus");
      let sharePrice = 0;
      let numShares = 0;
      let cost = 0;
      let account = null;
      let docSigned = false;

      if (investmentData.value.offering?.share_price) {
        sharePrice = investmentData.value.offering.share_price;
      }

      if (investmentData.value.investment?.num_shares) {
        numShares = investmentData.value.investment.num_shares;
        cost = numShares * sharePrice;
      }

      if (investmentData.value.investment?.account_id) {
        account = investmentData.value.accounts?.find((a) => {
          if (a.account_id === investmentData.value.investment.account_id) {
            return a;
          }
        });
      }

      if (investmentData.value.offering_statuses?.signed_documents) {
        docSigned = true;
      }

      console.log(
        `numShares: ${numShares}, cost: ${cost}, account: ${account}, docSigned: ${docSigned}`
      );

      // check if the investment is complete and the docs have been signed, if so lock the form down
      if (numShares !== 0 && cost !== 0 && account && docSigned) {
        const invDetailsComp =
          investmentDetailsComponent.value as InvestNowInvestmentFormInvestmentDetailsInterface;
        invDetailsComp.lockInvestment();

        const docSignComp =
          documentSignComponent.value as InvestNowInvestmentDocumentSignatureInterface;
        docSignComp.lockDocumentSigning();

        finalize.value = true;
      }
    };

    // api method declarations
    const loadAllInvestmentData = async () => {
      loading.value = true;
      await loadInvestmentData();
      await loadOfferingCompany();
      // await loadInvestmentMakeData();
      loading.value = false;

      await nextTick(() => {
        checkInvestmentStatus();
      });
    };

    const loadInvestmentData = async () => {
      console.log("loadInvestData");
      if (token !== "") {
        // loading.value = true;
        let req: PostInvestmentToken = {
          token: token,
        };
        try {
          let {
            headers,
            data,
          }: {
            headers: Dictionary;
            data: V1ResponseWrapper;
          } = await $api.v1.getInvestment(req);

          if (data.status === "success") {
            console.log("headers...");
            console.log(headers);

            if (headers["api-session-id"]) {
              store.commit(
                MutationType.SetV1SessionId,
                headers["api-session-id"]
              );
            }

            investmentData.value = data.data as MakeInvestmentResponse;
            // await loadOfferingCompany();
          } else {
            // api always returns a 200 this is a catch for any status besides a 'success'
            error.value = true;
          }
        } catch (e) {
          console.error(e);
          error.value = true;
        }
        // finally {
        //   // loading.value = false;
        // }
      } else {
        console.error("Handle no token...");
      }

      // if (!error.value) {
      //   await nextTick(() => {
      //     checkInvestmentStatus();
      //   });
      // }
      console.log("end loadInvestData");
    };

    const loadOfferingCompany = async () => {
      if (investmentData.value.offering?.offering_id) {
        let req: GetOfferingCompany = {
          offering_id: investmentData.value.offering.offering_id,
        };
        try {
          let { data }: { data: V1ResponseWrapper } =
            await $api.v1.getOfferingCompany(req);
          offeringCompany.value = data.data as OfferingCompany;
        } catch (e) {
          // todo: handle/display error
          console.error(e);
        }
      } else {
        // todo: handle/display error?
      }
    };

    // const loadInvestmentMakeData = async () => {
    //   console.log("loadInvestmentMakeData");
    //
    //   if (
    //     investmentData.value.investment?.investment_id &&
    //     investmentData.value.offering?.offering_id
    //   ) {
    //     let req: GetMakeInvestmentData = {
    //       investment_id: investmentData.value.investment.investment_id,
    //       offering_id: investmentData.value.offering.offering_id,
    //     };
    //     try {
    //       let { data }: { data: V1ResponseWrapper } =
    //         await $api.v1.postMakeInvestmentData(req);
    //
    //       console.log("here....");
    //       console.log(data);
    //     } catch (e) {
    //       // todo: handle/display error
    //       console.error(e);
    //     }
    //   } else {
    //     // todo: handle/display error?
    //   }
    //
    //   // /investment/get-make-data
    // };

    // ui methods
    const investmentIsSaving = () => {
      console.log("investmentIsSaving");
      const docSignComp =
        documentSignComponent.value as InvestNowInvestmentDocumentSignatureInterface;
      docSignComp.resetDocumentSigning();
    };

    const investmentDidSave = async (reissue: boolean) => {
      // re-sync data after investment save
      console.log(`investmentDidSave: ${reissue}`);
      await loadInvestmentData();
      console.log("investmentDidSave finish reloading");

      const docSignComp =
        documentSignComponent.value as InvestNowInvestmentDocumentSignatureInterface;
      docSignComp.requestDocumentSigning(reissue);
    };

    const filesDidUpdate = async () => {
      console.log(`filesDidUpdate`);
      await loadInvestmentData();
      console.log("filesDidUpdate finish reloading");
    };

    const documentSignInitialized = () => {
      console.log("documentSignInitialized");
      const docSignComp = document.querySelector("#documentSignComponent");
      docSignComp?.scrollIntoView({ behavior: "smooth" });
    };

    const documentSigned = () => {
      console.log("documentSigned");

      const invDetailsComp =
        investmentDetailsComponent.value as InvestNowInvestmentFormInvestmentDetailsInterface;
      invDetailsComp.lockInvestment();

      finalize.value = true;
    };

    const completeInvestment = () => {
      console.log("investmentComplete");
      router.push({
        name: "invest-now-complete-v1",
        params: {
          id: investmentData.value.offering.offering_id,
        },
      });
    };

    const formErrorEvent = (errorText: string) => {
      formErrorText.value = errorText;
      formError.value = true;
    };

    const closeFormError = () => {
      formError.value = false;
      formErrorText.value = "";
    };

    const redirectAccreditation = () => {
      window.location.href = process.env.VUE_APP_MARKETPLACE_URL;
    };

    // lifecycle hooks
    onMounted(() => {
      token = route.params.token as string;
      console.log(token);

      // loadInvestmentData();
      loadAllInvestmentData();
    });

    return {
      loading,
      error,
      finalize,
      investmentData,
      offeringCompany,
      formError,
      formErrorText,
      // computed
      unaccreditedError,
      // components
      investmentDetailsComponent,
      documentSignComponent,
      // methods
      investmentIsSaving,
      investmentDidSave,
      filesDidUpdate,
      documentSignInitialized,
      documentSigned,
      completeInvestment,
      formErrorEvent,
      closeFormError,
      redirectAccreditation,
    };
  },
});
</script>

<style lang="scss" scoped>
.make-investment-logo {
  max-width: 400px;
  max-height: 120px;
  mix-blend-mode: multiply;
}

.make-investment-title-container {
  background-color: #60879f;
}

.make-investment-title {
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.error-text {
  font-size: 16px;
}

.accreditation-error-dialog {
  min-width: 500px;

  .p-dialog-header-icons {
    display: none;
  }
}
</style>
