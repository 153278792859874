
import { computed, defineComponent, PropType, ref } from "vue";
import { MakeInvestmentResponse } from "@/interfaces/v1/MakeInvestmentResponse";
import { Formatter } from "@/utils/formatter";

import FileUploadDialog, {
  FileUploadConfig,
} from "@/components/v1/dialog/FileUploadDialog.vue";
import { V1ResponseWrapper } from "@/api/services/v1Payloads";

export interface InvestNowInvestmentFormAccreditationVerificationInterface {
  verificationType(): string;
  isValid(): boolean;
}

export default defineComponent({
  name: "InvestNowInvestmentFormAccreditationVerification",
  components: {
    FileUploadDialog,
  },
  props: {
    investmentData: {
      type: Object as PropType<MakeInvestmentResponse>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const DocumentEndpoint = "/document/upload-accreditation-document";
    const DocumentAssetsTypeId = "45";
    const DocumentVerifiedTypeId = "50";

    // refs
    const process = ref<string>();
    const incomeAllowed = ref(false);
    const assetsAllowed = ref(false);
    const verifiedAllowed = ref(false);

    const fileUploadName = ref("");
    const fileUploadDialog = ref(false);
    const fileUploadConfig = ref<FileUploadConfig>({
      endpoint: DocumentEndpoint,
      documentId: DocumentAssetsTypeId,
      ownerId: "",
      accountId: "",
      existingFiles: [],
    });

    // computed
    const hasAccreditationStatus = computed(() => {
      // check if investor is not accredited
      if (
        props.investmentData?.investors &&
        props.investmentData?.investors?.length > 0
      ) {
        const investor = props.investmentData.investors[0];
        if (!investor.investor_accredited) {
          return true;
        } else {
          if (props.investmentData?.accreditation?.status) {
            return props.investmentData.accreditation.status === "accepted";
          }

          return false;
        }
      }

      return true;
    });

    const isAccredited = computed(() => {
      if (
        props.investmentData?.investors &&
        props.investmentData?.investors?.length > 0
      ) {
        const investor = props.investmentData.investors[0];
        return investor.investor_accredited;
      }

      return false;
    });

    const accreditationStatus = computed(() => {
      if (
        props.investmentData?.investors &&
        props.investmentData?.investors?.length > 0
      ) {
        const investor = props.investmentData.investors[0];

        // non-accredited logic
        if (!investor.investor_accredited) {
          return "Non-Accredited";
        } else {
          if (props.investmentData?.accreditation?.status) {
            return Formatter.capitalizeString(
              props.investmentData.accreditation.status
            );
          }

          return "Unverified";
        }
      }

      return "Not Available";
    });

    const hasAccreditationDocs = computed(() => {
      let docId = "";
      if (process.value === "Assets") {
        docId = DocumentAssetsTypeId;
      }

      if (process.value === "Verified") {
        docId = DocumentVerifiedTypeId;
      }

      if (props.investmentData?.documents["accreditation"]) {
        const accreditationDocs =
          props.investmentData.documents["accreditation"];
        console.log(accreditationDocs);
        if (accreditationDocs[docId]) {
          const doc = accreditationDocs[docId];
          return doc.uploaded.length > 0;
        }
      }

      return false;
    });

    const accreditationDocsStat = computed(() => {
      let docId = "";
      if (process.value === "Assets") {
        docId = DocumentAssetsTypeId;
      }

      if (process.value === "Verified") {
        docId = DocumentVerifiedTypeId;
      }

      if (props.investmentData?.documents["accreditation"]) {
        const accreditationDocs =
          props.investmentData.documents["accreditation"];
        console.log(accreditationDocs);
        if (accreditationDocs[docId]) {
          const doc = accreditationDocs[docId];
          return `${doc.uploaded.length} document(s) have been uploaded`;
        }
      }

      return "Documents uploaded";
    });

    const hasAccreditationProcessStatus = computed(() => {
      return !!props.investmentData?.accreditation?.status;
    });

    const accreditationProcess = computed(() => {
      if (props.investmentData?.accreditation?.type) {
        return Formatter.capitalizeString(
          props.investmentData.accreditation.type
        );
      }

      return "Not Available";
    });

    const maxInvestmentAmount = computed(() => {
      if (
        props.investmentData?.investors &&
        props.investmentData?.investors?.length > 0
      ) {
        const investor = props.investmentData.investors[0];

        // non-accredited logic
        if (!investor.investor_accredited) {
          return Formatter.currency(investor.investor_limit);
        } else {
          // todo: accreditation logic
          return "Unlimited";
        }
      }

      return "Not Available";
    });

    const processOptions = computed(() => {
      let processes = [];

      if (props.investmentData?.offering.accreditation_income) {
        processes.push("Income");
      }

      if (props.investmentData?.offering.accreditation_assets) {
        processes.push("Assets");
      }

      if (props.investmentData?.offering.accreditation_verified) {
        processes.push("Verified");
      }

      return processes;
    });

    const fileUploadDialogTitle = computed(() => {
      if (process.value === "Assets") {
        return "Proof of Assets";
      }

      if (process.value === "Verified") {
        return "Proof of Accreditation";
      }

      return "Upload File";
    });

    // ui methods
    const uploadFileClick = () => {
      console.log("upload file");

      // set the correct document id
      if (process.value === "Assets") {
        fileUploadConfig.value.documentId = DocumentAssetsTypeId;
      } else if (process.value === "Verified") {
        fileUploadConfig.value.documentId = DocumentVerifiedTypeId;
      }

      // setup the existing files
      if (props.investmentData?.documents["accreditation"]) {
        const accreditationDocs =
          props.investmentData.documents["accreditation"];
        console.log(accreditationDocs);
        if (accreditationDocs[fileUploadConfig.value.documentId]) {
          const doc = accreditationDocs[fileUploadConfig.value.documentId];
          console.log(doc.uploaded);
          fileUploadConfig.value.existingFiles = doc.uploaded;
        }
      }

      // set the investor id
      if (
        props.investmentData?.investors &&
        props.investmentData?.investors?.length > 0
      ) {
        const investor = props.investmentData.investors[0];
        fileUploadConfig.value.ownerId = investor.investor_id;
      }

      fileUploadDialog.value = true;
    };

    const uploadFileComplete = (resp: V1ResponseWrapper) => {
      console.log("uploadFileComplete");

      if (resp.data) {
        console.log(resp.data["file_name"]);
        fileUploadName.value = resp.data["file_name"];
      }

      fileUploadDialog.value = false;
      emit("update");
    };

    const fileRemoved = () => {
      fileUploadDialog.value = false;
      emit("update");
    };

    const uploadFileCancel = () => {
      fileUploadDialog.value = false;
    };

    const verificationType = (): string => {
      return process.value ? process.value.toLowerCase() : "";
    };

    const isValid = (): boolean => {
      // todo: check if there are files for this type of accreditation

      if (hasAccreditationProcessStatus.value) {
        return true;
      }

      if (process.value === "Income" && incomeAllowed.value === true) {
        return true;
      } else if (
        process.value === "Assets" &&
        assetsAllowed.value === true &&
        fileUploadName.value !== ""
      ) {
        return true;
      } else if (
        process.value === "Verified" &&
        verifiedAllowed.value === true &&
        fileUploadName.value !== ""
      ) {
        return true;
      }

      return false;
    };

    return {
      // refs
      process,
      incomeAllowed,
      assetsAllowed,
      verifiedAllowed,
      fileUploadName,
      fileUploadDialog,
      fileUploadConfig,
      // computed
      hasAccreditationStatus,
      isAccredited,
      hasAccreditationDocs,
      accreditationDocsStat,
      accreditationStatus,
      hasAccreditationProcessStatus,
      accreditationProcess,
      maxInvestmentAmount,
      processOptions,
      fileUploadDialogTitle,
      // methods
      uploadFileClick,
      uploadFileComplete,
      fileRemoved,
      uploadFileCancel,
      verificationType,
      isValid,
    };
  },
});
