
import { defineComponent, onMounted, PropType, ref, inject } from "vue";
import { InvestmentDocumentFile } from "@/interfaces/v1/MakeInvestmentResponse";
import { V1ResponseWrapper } from "@/api/services/v1Payloads";

import { Api } from "@/api";

export interface FileCaptureConfig {
  endpoint: string;
  documentId: string;
  ownerId: string;
  accountId: string;
  existingFiles: InvestmentDocumentFile[];
}

export default defineComponent({
  name: "FileCaptureDialog",
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object as PropType<FileCaptureConfig>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const $api = inject("$api") as Api;

    // local
    let imageData: Blob | null = null;

    // refs
    const loading = ref(false);
    const processing = ref(false);
    const imageUrl = ref("");
    const errorMessage = ref("");

    // local methods
    const setupCamera = async () => {
      loading.value = true;
      const player = document.getElementById("player") as HTMLVideoElement;

      if (player) {
        player.onplaying = () => {
          console.log("video now playing...");
          loading.value = false;
        };

        try {
          const constraints = {
            video: true,
          };

          player.srcObject = await navigator.mediaDevices.getUserMedia(
            constraints
          );
        } catch (e) {
          console.error(e);
          loading.value = false;
        }
      }
    };

    // ui methods
    const takePhotoClick = () => {
      const player = document.getElementById("player") as HTMLVideoElement;
      const canvas = document.getElementById("canvas") as HTMLCanvasElement;
      const context = canvas.getContext("2d");

      if (player && canvas && context) {
        context.drawImage(player, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          imageData = blob;
        });
        imageUrl.value = canvas.toDataURL("image/png");
        // const filename = `image-capture-${new Date().getTime()}.png`;
        // console.log(filename);
      }
    };

    const retakePhotoClick = () => {
      console.log("retakePhotoClick");

      imageUrl.value = "";
      // setupCamera();
    };

    const uploadClick = async () => {
      processing.value = true;

      const image = imageData as Blob;
      const imageName = `file_capture-${new Date().getTime()}.png`;
      // const f = file as File;
      //
      // console.log(`file type: ${f.type}`);
      // if (!FileTypes.includes(f.type)) {
      //   errorMessage.value =
      //     "Invalid file type. Please upload an image, pdf, or doc file";
      //   processing.value = false;
      //   return;
      // }

      const formData = new FormData();
      if (props.config.endpoint === "/document/upload-accreditation-document") {
        formData.append("file", image, imageName);
        formData.append("investor_id", props.config.ownerId);
        formData.append("document_type_id", props.config.documentId);
        formData.append("document_content_type", "image/png");
      } else if (
        props.config.endpoint === "/document/upload-account-document"
      ) {
        formData.append("file", image, imageName);
        formData.append("account_id", props.config.accountId);
        formData.append("document_type_id", props.config.documentId);
        formData.append("document_content_type", "image/png");
      }
      // formData.append("file", image, "file_capture.png");
      // formData.append("investor_id", props.config.ownerId);
      // formData.append("document_type_id", props.config.documentId);
      // formData.append("document_content_type", "image/png");

      try {
        let { data }: { data: Blob } = await $api.v1.uploadFile(
          props.config.endpoint,
          formData
        );

        let resp = JSON.parse(await data.text()) as V1ResponseWrapper;

        // todo: error check
        if (resp.status === "success") {
          emit("success", resp);

          const player = document.getElementById("player") as HTMLVideoElement;
          if (player) {
            const stream = player.srcObject as MediaStream;
            stream.getTracks().forEach((t) => {
              t.stop();
            });
          }
        } else {
          if (resp.error) {
            errorMessage.value = resp.error;
          } else {
            errorMessage.value =
              "An error occurred with the upload. Please try again.";
          }
        }
      } catch (e) {
        // todo: handle/display error
        console.error(e);
      } finally {
        processing.value = false;
      }
    };

    // const okClick = () => {
    //   processing.value = true;
    //   // emit("ok");
    // };

    const cancelClick = () => {
      const player = document.getElementById("player") as HTMLVideoElement;
      if (player) {
        const stream = player.srcObject as MediaStream;
        stream.getTracks().forEach((t) => {
          t.stop();
        });
      }

      emit("cancel");
    };

    // lifecycle hooks
    onMounted(() => {
      setupCamera();
    });

    return {
      // variables
      loading,
      processing,
      imageUrl,
      errorMessage,
      // methods
      takePhotoClick,
      retakePhotoClick,
      uploadClick,
      cancelClick,
    };
  },
});
