
import {
  computed,
  defineComponent,
  inject,
  nextTick,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import { Emitter } from "mitt";
import { C } from "@/interfaces/constants";
import { Api } from "@/api";

import {
  PostACHAccount,
  PostInvestmentOfferingStatus,
  PostSaveAccreditation,
  PostSaveInvestment,
  V1ResponseWrapper,
} from "@/api/services/v1Payloads";
import { RoutingNumberResponse } from "@/api/services/routingnumbers";

import { DropdownEvent, InputNumberEvent } from "@/interfaces/events";
import { MakeInvestmentResponse } from "@/interfaces/v1/MakeInvestmentResponse";

import InvestNowInvestmentFormAccreditationVerification, {
  InvestNowInvestmentFormAccreditationVerificationInterface,
} from "@/components/v1/invest-now-investment-v1/InvestNowInvestmentFormAccreditationVerification.vue";

import FileUploadDialog, {
  FileUploadConfig,
} from "@/components/v1/dialog/FileUploadDialog.vue";

import FileCaptureDialog, {
  FileCaptureConfig,
} from "@/components/v1/dialog/FileCaptureDialog.vue";

interface AccountSource {
  id: string;
  type: string;
  name: string;
}

export interface InvestNowInvestmentFormInvestmentDetailsInterface {
  // setupInvestmentDetails(): void;
  lockInvestment(): void;
}

export default defineComponent({
  name: "InvestNowInvestmentFormInvestmentDetails",
  components: {
    FileUploadDialog,
    FileCaptureDialog,
    InvestNowInvestmentFormAccreditationVerification,
  },
  props: {
    investmentData: {
      type: Object as PropType<MakeInvestmentResponse>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const $api = inject("$api") as Api;
    const $eventBus = inject("$eventBus") as Emitter;

    // local variables
    const CheckType = "Check";
    const WireType = "Wire";
    const IRAType = "IRA";
    const ACHType = "ACH";
    const ACHNewType = "NEW_ACH";
    const ErrorInvestmentMinNotMet =
      "The number of shares does not meet the minimum investment amount.";
    const ErrorInvestmentMaxExceeded =
      "The number of shares exceeds the maximum investment amount.";

    let sharePrice = 0;
    let maxInvestment = 0;
    let minInvestment = 0;
    let investmentSaveError = "";

    // refs
    const accreditationComp =
      ref<InvestNowInvestmentFormAccreditationVerificationInterface>();
    const numShares = ref();
    const cost = ref();
    const investmentSource = ref<AccountSource>();

    const investmentLocked = ref(false);
    const investmentSaved = ref(false);
    const investmentSaving = ref(false);
    const investmentAmountError = ref(false);
    const investmentAmountErrorText = ref();

    const investmentExists = ref(false);
    const initNumShares = ref(0);
    const initCost = ref(0);
    const initInvestmentSource = ref<AccountSource>();
    const initAchAccount = ref<AccountSource>();
    const initHasIRAAccount = ref("Yes");

    const iraAccountSelected = ref(false);
    const hasIRAAccount = ref("Yes");
    const iraSourceOptions = ref([
      { label: "Invest with my IRA Financial IRA", value: "Yes" },
      { label: "Open new IRA with IRA Financial", value: "No" },
    ]);

    const achAccounts = ref<Array<AccountSource>>([]);
    const achAddAccount = ref(false);
    const achSelectAccount = ref(false);
    const achAccount = ref<AccountSource>();
    const achAccountNickname = ref("");
    const achAccountHolderName = ref("");
    const achAccountClass = ref("");
    const achRoutingNumber = ref();
    const achAccountNumber = ref();
    const achAccountNumberConfirm = ref();

    const routingNumberHelpText = ref("");
    const routingNumberError = ref(false);

    const investmentSourceOptions = ref<Array<AccountSource>>([]);
    const achAccountClassOptions = ref(["Checking", "Savings"]);

    const fileUploadDialog = ref(false);
    const fileUploadDialogTitle = ref("Upload License");
    const fileUploadConfig = ref<FileUploadConfig>({
      endpoint: "/document/upload-account-document", // DocumentEndpoint,
      documentId: "51", // DocumentAssetsTypeId,
      ownerId: "",
      accountId: "",
      existingFiles: [],
    });

    const fileCaptureDialog = ref(false);
    const fileCaptureConfig = ref<FileCaptureConfig>({
      endpoint: "/document/upload-account-document", // DocumentEndpoint,
      documentId: "51", // DocumentAssetsTypeId,
      ownerId: "",
      accountId: "",
      existingFiles: [],
    });

    // watchers
    watch(
      () => props.investmentData,
      () => {
        console.log(`WATcher Details InvestmentData`);

        loadOfferingData();
        loadAccountData();
        loadInvestmentData();
      }
    );

    // computed
    const accreditationRequired = computed(() => {
      return (
        props.investmentData?.offering?.accreditation_income ||
        props.investmentData?.offering?.accreditation_assets ||
        props.investmentData?.offering?.accreditation_verified
      );

      // return true;
    });

    const investorProfileName = computed(() => {
      if (
        props.investmentData?.investors &&
        props.investmentData?.investors?.length > 0
      ) {
        const investor = props.investmentData.investors[0];
        return investor.investor_name;
      }

      return "Not Available";
    });

    const showNextButton = computed(() => {
      // if this is a new investment always show
      // if (!investmentExists.value) {
      //   return true;
      // }
      //
      // if (!investmentSaved.value) {
      //   return true;
      // }

      // if the investment exists and any of the values change, show
      return (
        initCost.value !== cost.value ||
        initNumShares.value !== numShares.value ||
        initInvestmentSource.value !== investmentSource.value ||
        initAchAccount.value !== achAccount.value ||
        initHasIRAAccount.value !== hasIRAAccount.value ||
        !investmentSaved.value ||
        !investmentExists.value
      );
    });

    const nextButtonDisabled = computed(() => {
      // investment saving
      if (investmentSaving.value) {
        return true;
      }

      // check if accreditation form is valid
      let accreditationValid = false;
      if (accreditationRequired.value) {
        if (accreditationComp.value?.isValid()) {
          accreditationValid = true;
        }
      } else {
        accreditationValid = true;
      }

      // investment amount valid
      const amountValid = !investmentAmountError.value;

      let investmentSourceValid = false;

      if (
        investmentSource.value?.type &&
        investmentSource.value?.type === ACHType
      ) {
        if (achAccount.value?.id && achAccount.value?.id !== "") {
          // chose existing account
          investmentSourceValid = true;
        } else {
          // chose to add new account make sure all fields are valid
          investmentSourceValid = validateNewAchAccountFields();
        }
      } else if (
        investmentSource.value?.type &&
        (investmentSource.value?.type === "Check" ||
          investmentSource.value?.type === "Wire")
      ) {
        // wire or check was selected we're good
        investmentSourceValid = true;
      } else if (
        investmentSource.value?.type &&
        investmentSource.value?.type === "IRA"
      ) {
        if (
          hasIRAAccount.value === "No" &&
          props.investmentData.documents["account"] &&
          props.investmentData.documents["account"]["51"] &&
          props.investmentData.documents["account"]["51"].uploaded.length > 0
        ) {
          investmentSourceValid = true;
        } else if (hasIRAAccount.value === "Yes") {
          investmentSourceValid = true;
        }
      }

      console.log(
        `accreditationValid: ${accreditationValid}, amountValid: ${amountValid}, investmentSourceValid: ${investmentSourceValid}`
      );

      return !(accreditationValid && amountValid && investmentSourceValid);
    });

    const hasNewIRADocs = computed(() => {
      return (
        hasIRAAccount.value === "No" &&
        props.investmentData.documents["account"] &&
        props.investmentData.documents["account"]["51"] &&
        props.investmentData.documents["account"]["51"].uploaded.length > 0
      );
    });

    // local methods
    const loadOfferingData = () => {
      // load offering data
      if (props.investmentData?.offering?.share_price) {
        sharePrice = props.investmentData.offering.share_price;
      }

      if (props.investmentData?.offering?.minimum_investment) {
        minInvestment = props.investmentData.offering.minimum_investment;
      }

      if (props.investmentData?.offering?.maximum_investment) {
        maxInvestment = props.investmentData.offering.maximum_investment;
      }
    };

    const loadAccountData = () => {
      if (props.investmentData?.accounts) {
        // load any ach accounts setup
        achAccounts.value = props.investmentData.accounts
          .filter((a) => {
            return a.account_type === ACHType;
          })
          .map((a) => {
            return {
              id: a.account_id,
              type: a.account_type,
              name: a.account_nickname,
            };
          });
        // append new account option
        achAccounts.value.push({
          id: "",
          type: ACHNewType,
          name: "Add Account",
        });

        // fetch all non-ach account types
        investmentSourceOptions.value = props.investmentData.accounts
          .filter((a) => {
            // account_type is IRA only include if investor is us
            if (a.account_type === IRAType) {
              return !!(
                props.investmentData.investors.length &&
                props.investmentData.investors[0].investor_is_us
              );
            }

            return a.account_type !== ACHType;
          })
          .map((a) => {
            return {
              id: a.account_id,
              type: a.account_type,
              name: a.account_nickname,
            };
          });

        // append the generic ACH option
        investmentSourceOptions.value.push({
          id: "",
          type: ACHType,
          name: "ACH",
        });

        // filter options that are enabled
        investmentSourceOptions.value = investmentSourceOptions.value.filter(
          (a) => {
            if (a.type === CheckType) {
              return props.investmentData?.offering?.check_enabled;
            }

            if (a.type === WireType) {
              return props.investmentData?.offering?.wire_enabled;
            }

            if (a.type === IRAType) {
              return props.investmentData?.offering?.ira_enabled;
            }

            if (a.type === ACHType) {
              return props.investmentData?.offering?.ach_enabled;
            }

            return true;
          }
        );
      }
    };

    const loadInvestmentData = () => {
      achAddAccount.value = false;

      // load investment data
      if (props.investmentData?.investment) {
        // load securities
        if (props.investmentData.investment.num_shares) {
          numShares.value = props.investmentData.investment.num_shares;
          cost.value = numShares.value * sharePrice;

          // set the initial values for change detection
          initNumShares.value = numShares.value;
          initCost.value = cost.value;
        }

        // load associated account info
        const account = props.investmentData.accounts.find((a) => {
          if (a.account_id === props.investmentData.investment.account_id) {
            return a;
          }
        });
        if (account) {
          // set the selected source
          investmentSource.value = investmentSourceOptions.value.find((s) => {
            return s.type === account.account_type ? s : undefined;
          });

          // set the initial value for change detection
          initInvestmentSource.value = investmentSource.value;

          if (investmentSource.value) {
            if (investmentSource.value?.type === ACHType) {
              if (achAccounts.value.length > 1) {
                achSelectAccount.value = true;
              }
              // else {
              //   achAddAccount.value = true;
              // }

              // setting the selected ach account
              achAccount.value = achAccounts.value.find((a) => {
                return a.id === account.account_id ? a : undefined;
              });
              initAchAccount.value = achAccount.value;
            } else if (investmentSource.value?.type === IRAType) {
              iraAccountSelected.value = true;
              initHasIRAAccount.value = hasIRAAccount.value;
              fileUploadConfig.value.accountId = investmentSource.value.id;
              fileCaptureConfig.value.accountId = investmentSource.value.id;
            } else {
              achSelectAccount.value = false;
              achAddAccount.value = false;
            }
          }
        }

        // check all values to determine if an investment exists
        if (
          initNumShares.value !== 0 &&
          initCost.value !== 0 &&
          initInvestmentSource.value !== undefined
        ) {
          console.log(`initNumShares ${initNumShares.value}`);
          console.log(`initCost ${initCost.value}`);
          console.log(`initInvestmentSource ${initInvestmentSource.value}`);

          investmentExists.value = true;
        }

        if (investmentExists.value) {
          console.log("investment exists");
        } else {
          console.log("no investment exists");
        }
      }
    };

    const saveInvestment = async () => {
      emit("saving");
      investmentSaving.value = true;

      // if a new ach account is being added, process first
      let newAccountId = "";
      if (
        investmentSource.value?.type === ACHType &&
        achAccount.value?.id === ""
      ) {
        console.log("New ACH Account");
        const lookup = await validateRoutingNumber(achRoutingNumber.value);

        if (lookup && lookup.code !== 200) {
          routingNumberHelpText.value =
            "Routing number is invalid or was not found.";
          routingNumberError.value = true;
          return;
        } else {
          routingNumberHelpText.value = lookup?.customer_name
            ? lookup.customer_name
            : "";
          routingNumberError.value = false;
        }

        newAccountId = await postACHAccount();
        if (newAccountId === "") {
          emit(
            "error",
            "An error occurred while saving your new ACH account information. Please verify the information provided and try again. If the problem persists please contact support."
          );
          investmentSaving.value = false;
          return;
        }
      }

      // determine which account id to use for the investment (new or existing)
      let accountId = "";
      if (investmentSource.value?.type === ACHType) {
        if (achAccount.value?.id === "") {
          accountId = newAccountId;
        } else {
          accountId = achAccount.value?.id ? achAccount.value.id : "";
        }
      } else if (investmentSource.value?.id) {
        accountId = investmentSource.value.id;
      }

      // post the investment data
      let saved = await postInvestment(accountId);
      if (!saved) {
        let errorText =
          "An error occurred while saving your investment. Please verify the information provided and try again. If the problem persists please contact support.";
        if (investmentSaveError !== "") {
          errorText = `An error occurred while saving your investment. ${investmentSaveError} Please verify the information provided and try again. If the problem persists please contact support.`;
        }

        emit("error", errorText);
        investmentSaving.value = false;
        return;
      }

      // post accreditation data
      if (accreditationRequired.value) {
        saved = await postAccreditation();
        if (!saved) {
          emit(
            "error",
            "An error occurred while saving your accreditation information. Please verify the information provided and try again. If the problem persists please contact support."
          );
          investmentSaving.value = false;
          return;
        }
      }

      // post investment offering status
      saved = await postInvestmentOfferingStatus();
      if (!saved) {
        emit(
          "error",
          "An error occurred while updating the investment status. Please verify the information provided and try again. If the problem persists please contact support."
        );
        investmentSaving.value = false;
        return;
      }

      // if values changed, need to force a new document to be loaded
      let reissueDocuments = false;
      if (
        investmentExists.value &&
        (initNumShares.value !== numShares.value ||
          initCost.value !== cost.value ||
          initInvestmentSource.value !== investmentSource.value ||
          initAchAccount.value !== achAccount.value ||
          initHasIRAAccount.value !== hasIRAAccount.value)
      ) {
        reissueDocuments = true;
      }

      // update all the existing data to the now saved data
      investmentExists.value = true;
      initNumShares.value = numShares.value;
      initCost.value = cost.value;
      initInvestmentSource.value = investmentSource.value;
      initAchAccount.value = achAccount.value;
      initHasIRAAccount.value = hasIRAAccount.value;

      investmentSaving.value = false;
      investmentSaved.value = true;

      emit("saved", reissueDocuments);
    };

    const postACHAccount = async (): Promise<string> => {
      const achReq: PostACHAccount = {
        accounts: [
          {
            provider_context: {
              account_number: achAccountNumber.value.toString(),
              routing_number: achRoutingNumber.value.toString(),
              account_class: achAccountClass.value,
            },
            provider_identifier: "none",
            account_provider_verified: "accepted",
            investor_id: props.investmentData.investors[0].investor_id,
            account_id: null,
            account_enabled: true,
            account_number: achAccountNumber.value.toString(),
            routing_number: achRoutingNumber.value.toString(),
            account_nickname: achAccountNickname.value,
            account_holder_name: achAccountHolderName.value,
            account_type: "ACH",
            account_class: achAccountClass.value,
          },
        ],
      };

      try {
        let { data }: { data: V1ResponseWrapper } =
          await $api.v1.saveACHAccount(achReq);
        console.log(data);

        if (data.status === "success" && data.data) {
          let newAcct = data.data[0];
          return newAcct.account_id;
        } else {
          return "";
        }
      } catch (e) {
        console.error(e);
        return "";
      }
    };

    const postInvestment = async (accountId: string): Promise<boolean> => {
      try {
        const req: PostSaveInvestment = {
          user_id: props.investmentData.investors[0].user_id,
          investment_id: props.investmentData.investment.investment_id,
          offering_id: props.investmentData.offering.offering_id,
          investor_id: props.investmentData.investors[0].investor_id,
          amount: `${cost.value}`,
          num_shares: `${numShares.value}`,
          account_id: accountId,
          admin_verified: props.investmentData.investment.admin_verified,
          issuer_verified: props.investmentData.investment.issuer_verified,
          offering_regulation_type:
            props.investmentData.offering.offering_regulation_type,
        };

        console.table(req);

        const { data }: { data: V1ResponseWrapper } =
          await $api.v1.saveInvestment(req);
        console.log(data);

        investmentSaveError = "";
        if (data.status === "fail" && data.error) {
          investmentSaveError = data.error;
        }

        return data.status === "success";
      } catch (e) {
        console.error(e);
        return false;
      }
    };

    const postAccreditation = async (): Promise<boolean> => {
      try {
        let type = "";
        // todo: look at current type if one exists else use the accreditaiotn comp
        if (props.investmentData.accreditation.type) {
          type = props.investmentData.accreditation.type;
        } else if (accreditationComp.value) {
          type = accreditationComp.value.verificationType();
        }

        const req: PostSaveAccreditation = {
          user_id: props.investmentData.investors[0].user_id,
          investor_id: props.investmentData.investors[0].investor_id,
          status: "pending", // todo: pull from validate-token payload?
          type: type,
          notes: null,
          admin_id: null,
        };

        const { data }: { data: V1ResponseWrapper } =
          await $api.v1.saveAccreditation(req);
        console.log("save accreditation");
        console.log(data);

        return data.status === "success";
      } catch (e) {
        console.error(e);
        return false;
      }
    };

    const postInvestmentOfferingStatus = async (): Promise<boolean> => {
      try {
        const req: PostInvestmentOfferingStatus = {
          user_id: props.investmentData.investors[0].user_id,
          investment_id: props.investmentData.investment.investment_id,
          investor_id: props.investmentData.investors[0].investor_id,
          offering_id: props.investmentData.offering.offering_id,
          signed_documents: false,
          reviewed_offering_docs:
            props.investmentData.offering_statuses.reviewed_offering_docs,
          entity_id: props.investmentData.offering_statuses.entity_id,
          downloaded_offering_docs_date:
            props.investmentData.offering_statuses
              .downloaded_offering_docs_date,
          downloaded_offering_docs:
            props.investmentData.offering_statuses.downloaded_offering_docs,
          signed_docs_date: null,
          reviewed_offering_docs_date:
            props.investmentData.offering_statuses.reviewed_offering_docs_date,
          complete_profile:
            props.investmentData.investors[0].form_complete === 100,
        };

        console.log(req);

        const { data }: { data: V1ResponseWrapper } =
          await $api.v1.saveInvestmentOfferingStatus(req);

        return data.status === "success";
      } catch (e) {
        console.error(e);
        return false;
      }
    };

    const validateRoutingNumber = async (
      rn: string
    ): Promise<RoutingNumberResponse | null> => {
      console.log("validateRoutingNumber");

      try {
        let { data }: { data: RoutingNumberResponse } =
          await $api.routingNumber.lookup(rn);
        console.log(data);

        return data;
      } catch (e) {
        return null;
      }
    };

    const validateNewAchAccountFields = (): boolean => {
      let achFormValid = true;
      if (!achAccountNickname.value || achAccountNickname.value === "") {
        // todo: show error
        achFormValid = false;
      }

      if (!achAccountHolderName.value || achAccountHolderName.value === "") {
        // todo: show error
        achFormValid = false;
      }

      if (!achAccountClass.value || achAccountClass.value === "") {
        // todo: show error
        achFormValid = false;
      }

      if (
        !achRoutingNumber.value ||
        achRoutingNumber.value === "" ||
        routingNumberError.value
      ) {
        // todo: show error
        achFormValid = false;
      }

      if (!achAccountNumber.value || achAccountNumber.value === "") {
        // todo: show error
        achFormValid = false;
      }

      if (
        !achAccountNumberConfirm.value ||
        achAccountNumberConfirm.value === ""
      ) {
        // todo: show error
        achFormValid = false;
      }

      // accountNumber/Confirm do not match
      if (achAccountNumber.value !== achAccountNumberConfirm.value) {
        // todo: show error
        achFormValid = false;
      }

      return achFormValid;
    };

    // interface methods
    const setupInvestmentDetails = () => {
      loadOfferingData();
      loadAccountData();
      loadInvestmentData();
    };

    const lockInvestment = () => {
      investmentLocked.value = true;
    };

    // ui methods
    const numSharesInput = (e: InputNumberEvent) => {
      // not sure why this has to be done but ???
      numShares.value = e.value;
      cost.value = numShares.value * sharePrice;

      if (cost.value > maxInvestment) {
        investmentAmountError.value = true;
        investmentAmountErrorText.value = ErrorInvestmentMaxExceeded;
      } else {
        investmentAmountError.value = false;
      }
    };

    const numSharesBlur = (e: Event) => {
      // implemented here to handle Android Chrome browser issues
      numShares.value = (e.target as HTMLInputElement).value;

      const shares = parseInt(numShares.value.replace(",", ""), 10);
      cost.value = shares * sharePrice;

      investmentAmountError.value = false;
      if (cost.value > maxInvestment) {
        investmentAmountError.value = true;
        investmentAmountErrorText.value = ErrorInvestmentMaxExceeded;
      }

      if (cost.value < minInvestment) {
        investmentAmountError.value = true;
        investmentAmountErrorText.value = ErrorInvestmentMinNotMet;
      }
    };

    const investmentSourceChange = (e: DropdownEvent) => {
      const source = e.value as AccountSource;

      achSelectAccount.value = false;
      achAddAccount.value = false;
      iraAccountSelected.value = false;

      if (source.type === ACHType) {
        if (achAccounts.value.length > 1) {
          achSelectAccount.value = true;
        } else {
          achAddAccount.value = true;
          achAccount.value = source;
        }
      } else if (source.type === IRAType) {
        console.log("ira selected");
        iraAccountSelected.value = true;
        fileUploadConfig.value.accountId = source.id;
        fileCaptureConfig.value.accountId = source.id;
      }
    };

    const achAccountChange = (e: DropdownEvent) => {
      const source = e.value as AccountSource;
      achAddAccount.value = source.type === ACHNewType;
    };

    const routingNumberInput = () => {
      nextTick(() => {
        achRoutingNumber.value = achRoutingNumber.value.replace(/[^0-9]/g, "");
      });
    };

    const routingNumberValidate = async () => {
      if (achRoutingNumber.value !== "") {
        const lookup = await validateRoutingNumber(achRoutingNumber.value);

        if (lookup && lookup.code !== 200) {
          routingNumberHelpText.value =
            "Routing number is invalid or was not found.";
          routingNumberError.value = true;
        } else {
          routingNumberHelpText.value = lookup?.customer_name
            ? lookup.customer_name
            : "";
          routingNumberError.value = false;
        }
      }
    };

    const accountNumberInput = (e: InputNumberEvent) => {
      // not sure why this has to be done but ???
      achAccountNumber.value = e.value;
    };

    const accountNumberConfirmInput = (e: InputNumberEvent) => {
      // not sure why this has to be done but ???
      achAccountNumberConfirm.value = e.value;
    };

    const uploadLicensePhotoButton = () => {
      console.log("uploadLicensePhotoButton");
      fileUploadDialog.value = true;
    };

    const takeLicensePhotoButton = () => {
      fileCaptureDialog.value = true;
      console.log("takeLicensePhotoButton");
    };

    const uploadFileComplete = () => {
      console.log("uploadFileComplete");
      emit("filesUpdate");
      fileUploadDialog.value = false;
    };

    const fileRemoved = () => {
      console.log("fileRemoved");
    };

    const uploadFileCancel = () => {
      fileUploadDialog.value = false;
    };

    const fileCaptureDialogSuccess = () => {
      console.log("fileCaptureDialogOk");
      emit("filesUpdate");
      fileCaptureDialog.value = false;
    };

    const fileCaptureDialogCancel = () => {
      fileCaptureDialog.value = false;
    };

    const nextButton = () => {
      if (iraAccountSelected.value && hasIRAAccount.value === "No") {
        $eventBus.emit(C.EMIT_EVENTS.NEW_IRA, "yes");
      }

      saveInvestment();
    };

    const filesUpdated = () => {
      console.log("InvestmentDetails filesUpdated");
      emit("filesUpdate");
    };

    // lifecycle hooks
    onMounted(() => {
      setupInvestmentDetails();
    });

    return {
      // refs
      accreditationComp,
      numShares,
      cost,
      investmentSource,
      investmentLocked,
      investmentSaved,
      investmentSaving,
      investmentAmountError,
      investmentAmountErrorText,
      iraAccountSelected,
      hasIRAAccount,
      iraSourceOptions,
      achAccounts,
      achAddAccount,
      achSelectAccount,
      achAccount,
      achAccountNickname,
      achAccountHolderName,
      achAccountClass,
      achRoutingNumber,
      achAccountNumber,
      achAccountNumberConfirm,
      routingNumberHelpText,
      routingNumberError,
      investmentSourceOptions,
      achAccountClassOptions,
      fileUploadDialog,
      fileUploadDialogTitle,
      fileUploadConfig,
      fileCaptureDialog,
      fileCaptureConfig,
      // computed
      accreditationRequired,
      investorProfileName,
      showNextButton,
      nextButtonDisabled,
      hasNewIRADocs,
      // interface
      setupInvestmentDetails,
      lockInvestment,
      // methods
      numSharesInput,
      numSharesBlur,
      investmentSourceChange,
      achAccountChange,
      routingNumberInput,
      routingNumberValidate,
      accountNumberInput,
      accountNumberConfirmInput,
      uploadLicensePhotoButton,
      takeLicensePhotoButton,
      uploadFileComplete,
      fileRemoved,
      uploadFileCancel,
      fileCaptureDialogSuccess,
      fileCaptureDialogCancel,
      nextButton,
      filesUpdated,
    };
  },
});
