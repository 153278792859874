
import { defineComponent, inject, PropType, ref, computed } from "vue";

import { Api } from "@/api";
import {
  V1ResponseWrapper,
  DeleteAccreditationDoc,
} from "@/api/services/v1Payloads";
import { InvestmentDocumentFile } from "@/interfaces/v1/MakeInvestmentResponse";

export interface FileUploadConfig {
  endpoint: string;
  documentId: string;
  ownerId: string;
  accountId: string;
  existingFiles: InvestmentDocumentFile[];
}

export default defineComponent({
  name: "FileUploadDialog",
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Upload File",
    },
    config: {
      type: Object as PropType<FileUploadConfig>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const $api = inject("$api") as Api;

    const FileTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/gif",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
    ];

    // local variables
    let file: File | null = null;

    // refs
    const processing = ref(false);
    const fileAction = ref("upload");
    const fileActions = ref([
      { name: "Upload New File", value: "upload" },
      { name: "Remove Existing Files", value: "remove" },
    ]);

    const fileUpload = ref<HTMLInputElement | null>(null);
    const fileName = ref("");

    const selectedFiles = ref<InvestmentDocumentFile[]>([]);

    const errorMessage = ref("");

    // computed
    const acceptFileTypes = computed(() => {
      return FileTypes.join(",");
    });

    const hasUploadedFiles = computed(() => {
      console.log(`hasUploadedFiles: ${props.config.existingFiles.length > 0}`);
      return props.config.existingFiles.length > 0;
    });

    // ui methods
    const browseClick = () => {
      fileUpload.value?.click();
    };

    const fileChanged = () => {
      if (fileUpload.value?.files && fileUpload.value?.files?.length > 0) {
        file = fileUpload.value?.files[0];
        fileName.value = file.name;
        console.log(file);
      }
    };

    const uploadClick = async () => {
      processing.value = true;

      const f = file as File;

      console.log(`file type: ${f.type}`);
      if (!FileTypes.includes(f.type)) {
        errorMessage.value =
          "Invalid file type. Please upload an image, pdf, or doc file";
        processing.value = false;
        return;
      }

      const formData = new FormData();

      if (props.config.endpoint === "/document/upload-accreditation-document") {
        formData.append("file", f, f.name);
        formData.append("investor_id", props.config.ownerId);
        formData.append("document_type_id", props.config.documentId);
        formData.append("document_content_type", f.type);
      } else if (
        props.config.endpoint === "/document/upload-account-document"
      ) {
        formData.append("file", f, f.name);
        formData.append("account_id", props.config.accountId);
        formData.append("document_type_id", props.config.documentId);
        formData.append("document_content_type", f.type);
      }

      // formData.append("file", f, f.name);
      // formData.append("investor_id", props.config.ownerId);
      // formData.append("document_type_id", props.config.documentId);
      // formData.append("document_content_type", f.type);

      try {
        let { data }: { data: Blob } = await $api.v1.uploadFile(
          props.config.endpoint,
          formData
        );

        let resp = JSON.parse(await data.text()) as V1ResponseWrapper;

        // todo: error check
        if (resp.status === "success") {
          emit("success", resp);
        } else {
          if (resp.error) {
            errorMessage.value = resp.error;
          } else {
            errorMessage.value =
              "An error occurred with the upload. Please try again.";
          }
        }
      } catch (e) {
        // todo: handle/display error
        console.error(e);
      } finally {
        processing.value = false;
      }
    };

    const removeClick = async () => {
      processing.value = true;

      console.log("remove");
      console.log(selectedFiles.value);
      for (const f of selectedFiles.value) {
        try {
          const payload: DeleteAccreditationDoc = {
            document_id: f.document_id,
            investor_id: f.investor_id,
          };

          await $api.v1.deleteAccreditationDoc(payload);
        } catch (e) {
          console.error(e);
        }
      }

      processing.value = false;
      emit("removed");
    };

    const cancelClick = () => {
      emit("cancel");
    };

    return {
      // refs
      processing,
      fileAction,
      fileActions,
      fileUpload,
      fileName,
      selectedFiles,
      errorMessage,
      // computed
      acceptFileTypes,
      hasUploadedFiles,
      // methods
      browseClick,
      fileChanged,
      uploadClick,
      removeClick,
      cancelClick,
    };
  },
});
