
import {
  defineComponent,
  inject,
  ref,
  PropType,
  onMounted,
  onUnmounted,
} from "vue";
import { useRoute } from "vue-router";
import { Emitter } from "mitt";
import { C } from "@/interfaces/constants";
import { Api } from "@/api";

import HelloSign from "hellosign-embedded";

import { Dictionary } from "@/interfaces";
import { MakeInvestmentResponse } from "@/interfaces/v1/MakeInvestmentResponse";
import { HelloSignEvent } from "@/interfaces/hellosign/Event";
import { InvestmentDocumentsResp } from "@/api/services/v1HelloSign";
import {
  PostInvestmentOfferingStatus,
  V1ResponseWrapper,
} from "@/api/services/v1Payloads";

export interface InvestNowInvestmentDocumentSignatureInterface {
  lockDocumentSigning(): void;
  resetDocumentSigning(): void;
  requestDocumentSigning(reissue: boolean): Promise<boolean>;
}

export default defineComponent({
  name: "InvestNowInvestmentDocumentSignature",
  props: {
    investmentData: {
      type: Object as PropType<MakeInvestmentResponse>,
      required: true,
    },
  },
  setup(props, { emit }) {
    // https://github.com/hellosign/hellosign-embedded/wiki/API-Documentation-(v2)#on

    const $api = inject("$api") as Api;
    const $eventBus = inject("$eventBus") as Emitter;

    const route = useRoute();

    const client = new HelloSign();

    let token = "";
    let newIra = "no";

    // refs
    const active = ref(false);
    const loading = ref(false);
    const documentReady = ref(false);
    const documentSigned = ref(false);
    const documentError = ref(false);

    // interface methods
    const lockDocumentSigning = (): void => {
      active.value = true;
      documentSigned.value = true;
    };
    const resetDocumentSigning = (): void => {
      console.log("resetDocumentSigning");
      active.value = false;
      loading.value = false;
      documentReady.value = false;
      documentError.value = false;
    };

    const requestDocumentSigning = async (
      reissue: boolean
    ): Promise<boolean> => {
      console.log(`reissue docs: ${reissue}`);

      active.value = true;
      loading.value = true;
      documentReady.value = false;
      documentError.value = false;

      console.log("requestDocumentSigning");

      try {
        let params: Dictionary = {
          token: token,
          reissue: reissue,
        };

        if (newIra === "yes") {
          params["new_ira"] = newIra;
        }

        let { data }: { data: InvestmentDocumentsResp } =
          await $api.v1HelloSign.requestInvestmentDocuments(
            props.investmentData.investment.investment_id,
            params
          );
        console.log(data);

        documentReady.value = true;

        // const client = new HelloSign();
        // client.on("message", hsDocumentMessage);
        // client.on("sign", hsDocumentSign);
        // client.on("ready", hsDocumentReady);
        // client.on("decline", hsDocumentDecline);
        // client.on("cancel", hsDocumentCancel);
        // client.on("error", hsDocumentError);

        client.open(data.doc_url, {
          clientId: data.client_id,
          skipDomainVerification: process.env.VUE_APP_HS_SKIP_DOMAIN === "true",
          container: document.getElementById(
            "hellosign-container"
          ) as HTMLElement,
        });

        // showSignature.value = true;
        return true;
      } catch (e) {
        console.error(e);
        documentError.value = true;
        return false;
      } finally {
        loading.value = false;
      }
    };

    // local methods
    const postInvestmentOfferingStatus = async () => {
      // post investment offering status
      try {
        const req: PostInvestmentOfferingStatus = {
          user_id: props.investmentData.investors[0].user_id,
          investment_id: props.investmentData.investment.investment_id,
          investor_id: props.investmentData.investors[0].investor_id,
          offering_id: props.investmentData.offering.offering_id,
          signed_documents: true,
          reviewed_offering_docs:
            props.investmentData.offering_statuses.reviewed_offering_docs,
          entity_id: props.investmentData.offering_statuses.entity_id,
          downloaded_offering_docs_date:
            props.investmentData.offering_statuses
              .downloaded_offering_docs_date,
          downloaded_offering_docs:
            props.investmentData.offering_statuses.downloaded_offering_docs,
          signed_docs_date: null,
          reviewed_offering_docs_date:
            props.investmentData.offering_statuses.reviewed_offering_docs_date,
          complete_profile:
            props.investmentData.investors[0].form_complete === 100,
        };

        console.log(req);

        const { data }: { data: V1ResponseWrapper } =
          await $api.v1.saveInvestmentOfferingStatus(req);

        console.log(data);

        // todo: check for errors
      } catch (e) {
        console.error(e);

        // todo: display error
      }
    };

    // have to use any here since the interface can not be imported nor cast to a different type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hsDocumentMessage = (data: any) => {
      console.log(`hsDocumentMessage: ${data}`);
      console.log(data);
      if (data.type === HelloSign.messages.APP_INITIALIZE) {
        emit("initialized");
      }
    };

    const hsDocumentSign = (data: HelloSignEvent) => {
      console.log(`hsDocumentSign: ${data}`);

      postInvestmentOfferingStatus();

      documentSigned.value = true;

      emit("signed");
      // active.value = false;
      // documentReady.value = false;
    };

    const hsDocumentReady = (data: HelloSignEvent) => {
      console.log(`hsDocumentReady: ${data}`);
    };

    const hsDocumentDecline = (data: HelloSignEvent) => {
      console.log(`hsDocumentDecline: ${data}`);
    };

    const hsDocumentCancel = () => {
      console.log(`hsDocumentCancel`);
    };

    const hsDocumentError = (data: HelloSignEvent) => {
      console.log(`hsDocumentError: ${data}`);
    };

    const isNewIRA = (e: string | undefined) => {
      if (e) {
        console.log(e);
        newIra = e as string;
      }
    };

    // setup hellosign listeners
    client.on("message", hsDocumentMessage);
    client.on("sign", hsDocumentSign);
    client.on("ready", hsDocumentReady);
    client.on("decline", hsDocumentDecline);
    client.on("cancel", hsDocumentCancel);
    client.on("error", hsDocumentError);

    // lifecycle hooks
    onMounted(() => {
      token = route.params.token as string;
      console.log(`signature: ${token}`);

      $eventBus.on(C.EMIT_EVENTS.NEW_IRA, isNewIRA);
    });

    onUnmounted(() => {
      $eventBus.off(C.EMIT_EVENTS.NEW_IRA, isNewIRA);
    });

    return {
      // refs
      active,
      loading,
      documentReady,
      documentSigned,
      documentError,
      // showSignature,
      // interface
      lockDocumentSigning,
      resetDocumentSigning,
      requestDocumentSigning,
    };
  },
});
