interface CONST {
  REGEX: REGEX;
  ROLES: ROLES;
  EMIT_EVENTS: EMIT_EVENTS;
}

interface REGEX {
  EMAIL: RegExp;
}

interface ROLES {
  SUPER_ADMIN: string;
  ADMIN: string;
  BROKER: string;
  INVESTOR: string;
}

interface EMIT_EVENTS {
  NEW_IRA: string;
}

export const C: CONST = {
  REGEX: {
    EMAIL:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
  ROLES: {
    SUPER_ADMIN: "SUPER_ADMIN",
    ADMIN: "ADMIN",
    BROKER: "BROKER",
    INVESTOR: "INVESTOR",
  },
  EMIT_EVENTS: {
    NEW_IRA: "NEW_IRA",
  },
};
