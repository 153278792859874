
import {
  defineComponent,
  inject,
  onMounted,
  ref,
  computed,
  nextTick,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { Api } from "@/api";
import { Dictionary } from "@/interfaces";
import { MutationType } from "@/store/mutations";

import InvestNowInvestmentFormOfferingInfo from "@/components/v1/invest-now-investment-v1/InvestNowInvestmentFormOfferingInfo.vue";
import InvestNowInvestmentFormInvestmentDetails, {
  InvestNowInvestmentFormInvestmentDetailsInterface,
} from "@/components/v1/invest-now-investment-v1/InvestNowInvestmentFormInvestmentDetails.vue";
import InvestNowInvestmentDocumentSignature, {
  InvestNowInvestmentDocumentSignatureInterface,
} from "@/components/v1/invest-now-investment-v1/InvestNowInvestmentDocumentSignature.vue";
import InvestNowInvestmentCompleteInvestment from "@/components/v1/invest-now-investment-v1/InvestNowInvestmentCompleteInvestment.vue";

import {
  GetOfferingCompany,
  PostInvestmentToken,
  V1ResponseWrapper,
} from "@/api/services/v1Payloads";

import { MakeInvestmentResponse } from "@/interfaces/v1/MakeInvestmentResponse";
import { OfferingCompany } from "@/interfaces/v1/offeringCompany";

export default defineComponent({
  name: "InvestNowInvestmentForm",
  components: {
    InvestNowInvestmentFormOfferingInfo,
    InvestNowInvestmentFormInvestmentDetails,
    InvestNowInvestmentDocumentSignature,
    InvestNowInvestmentCompleteInvestment,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const $api = inject("$api") as Api;
    /*
    1) ???
      /investment/auth-token
      {token: TOKEN}
2)
      /investment/validate-token
      {token: TOKEN}
      -- investment, offering,  etc....


      /investment/get-make-data
      {offering_id, }

     */

    const loading = ref(false);
    const error = ref(false);
    const finalize = ref(false);
    const investmentData = ref({} as MakeInvestmentResponse);
    const offeringCompany = ref({} as OfferingCompany);

    const investmentDetailsComponent =
      ref<InvestNowInvestmentFormInvestmentDetailsInterface | null>(null);
    const documentSignComponent =
      ref<InvestNowInvestmentDocumentSignatureInterface | null>(null);

    let token = "";

    const unaccreditedError = computed(() => {
      // inline if with this is throwing an always false error???
      const numInvestors = investmentData.value.investors
        ? investmentData.value.investors.length
        : 0;
      if (numInvestors === 0) {
        return false;
      }

      return !!(
        (investmentData.value.offering?.accreditation_income ||
          investmentData.value.offering?.accreditation_assets ||
          investmentData.value.offering?.accreditation_verified) &&
        !investmentData.value.investors[0].investor_accredited
      );
    });

    const formError = ref(false);
    const formErrorText = ref("");

    const checkInvestmentStatus = () => {
      console.log("checkInvestmentStatus");
      let sharePrice = 0;
      let numShares = 0;
      let cost = 0;
      let account = null;
      let docSigned = false;

      if (investmentData.value.offering?.share_price) {
        sharePrice = investmentData.value.offering.share_price;
      }

      if (investmentData.value.investment?.num_shares) {
        numShares = investmentData.value.investment.num_shares;
        cost = numShares * sharePrice;
      }

      if (investmentData.value.investment?.account_id) {
        account = investmentData.value.accounts?.find((a) => {
          if (a.account_id === investmentData.value.investment.account_id) {
            return a;
          }
        });
      }

      if (investmentData.value.offering_statuses?.signed_documents) {
        docSigned = true;
      }

      console.log(
        `numShares: ${numShares}, cost: ${cost}, account: ${account}, docSigned: ${docSigned}`
      );

      // check if the investment is complete and the docs have been signed, if so lock the form down
      if (numShares !== 0 && cost !== 0 && account && docSigned) {
        const invDetailsComp =
          investmentDetailsComponent.value as InvestNowInvestmentFormInvestmentDetailsInterface;
        invDetailsComp.lockInvestment();

        const docSignComp =
          documentSignComponent.value as InvestNowInvestmentDocumentSignatureInterface;
        docSignComp.lockDocumentSigning();

        finalize.value = true;
      }
    };

    // api method declarations
    const loadAllInvestmentData = async () => {
      loading.value = true;
      await loadInvestmentData();
      await loadOfferingCompany();
      // await loadInvestmentMakeData();
      loading.value = false;

      await nextTick(() => {
        checkInvestmentStatus();
      });
    };

    const loadInvestmentData = async () => {
      console.log("loadInvestData");
      if (token !== "") {
        // loading.value = true;
        let req: PostInvestmentToken = {
          token: token,
        };
        try {
          let {
            headers,
            data,
          }: {
            headers: Dictionary;
            data: V1ResponseWrapper;
          } = await $api.v1.getInvestment(req);

          if (data.status === "success") {
            console.log("headers...");
            console.log(headers);

            if (headers["api-session-id"]) {
              store.commit(
                MutationType.SetV1SessionId,
                headers["api-session-id"]
              );
            }

            investmentData.value = data.data as MakeInvestmentResponse;
            // await loadOfferingCompany();
          } else {
            // api always returns a 200 this is a catch for any status besides a 'success'
            error.value = true;
          }
        } catch (e) {
          console.error(e);
          error.value = true;
        }
        // finally {
        //   // loading.value = false;
        // }
      } else {
        console.error("Handle no token...");
      }

      // if (!error.value) {
      //   await nextTick(() => {
      //     checkInvestmentStatus();
      //   });
      // }
      console.log("end loadInvestData");
    };

    const loadOfferingCompany = async () => {
      if (investmentData.value.offering?.offering_id) {
        let req: GetOfferingCompany = {
          offering_id: investmentData.value.offering.offering_id,
        };
        try {
          let { data }: { data: V1ResponseWrapper } =
            await $api.v1.getOfferingCompany(req);
          offeringCompany.value = data.data as OfferingCompany;
        } catch (e) {
          // todo: handle/display error
          console.error(e);
        }
      } else {
        // todo: handle/display error?
      }
    };

    // const loadInvestmentMakeData = async () => {
    //   console.log("loadInvestmentMakeData");
    //
    //   if (
    //     investmentData.value.investment?.investment_id &&
    //     investmentData.value.offering?.offering_id
    //   ) {
    //     let req: GetMakeInvestmentData = {
    //       investment_id: investmentData.value.investment.investment_id,
    //       offering_id: investmentData.value.offering.offering_id,
    //     };
    //     try {
    //       let { data }: { data: V1ResponseWrapper } =
    //         await $api.v1.postMakeInvestmentData(req);
    //
    //       console.log("here....");
    //       console.log(data);
    //     } catch (e) {
    //       // todo: handle/display error
    //       console.error(e);
    //     }
    //   } else {
    //     // todo: handle/display error?
    //   }
    //
    //   // /investment/get-make-data
    // };

    // ui methods
    const investmentIsSaving = () => {
      console.log("investmentIsSaving");
      const docSignComp =
        documentSignComponent.value as InvestNowInvestmentDocumentSignatureInterface;
      docSignComp.resetDocumentSigning();
    };

    const investmentDidSave = async (reissue: boolean) => {
      // re-sync data after investment save
      console.log(`investmentDidSave: ${reissue}`);
      await loadInvestmentData();
      console.log("investmentDidSave finish reloading");

      const docSignComp =
        documentSignComponent.value as InvestNowInvestmentDocumentSignatureInterface;
      docSignComp.requestDocumentSigning(reissue);
    };

    const filesDidUpdate = async () => {
      console.log(`filesDidUpdate`);
      await loadInvestmentData();
      console.log("filesDidUpdate finish reloading");
    };

    const documentSignInitialized = () => {
      console.log("documentSignInitialized");
      const docSignComp = document.querySelector("#documentSignComponent");
      docSignComp?.scrollIntoView({ behavior: "smooth" });
    };

    const documentSigned = () => {
      console.log("documentSigned");

      const invDetailsComp =
        investmentDetailsComponent.value as InvestNowInvestmentFormInvestmentDetailsInterface;
      invDetailsComp.lockInvestment();

      finalize.value = true;
    };

    const completeInvestment = () => {
      console.log("investmentComplete");
      router.push({
        name: "invest-now-complete-v1",
        params: {
          id: investmentData.value.offering.offering_id,
        },
      });
    };

    const formErrorEvent = (errorText: string) => {
      formErrorText.value = errorText;
      formError.value = true;
    };

    const closeFormError = () => {
      formError.value = false;
      formErrorText.value = "";
    };

    const redirectAccreditation = () => {
      window.location.href = process.env.VUE_APP_MARKETPLACE_URL;
    };

    // lifecycle hooks
    onMounted(() => {
      token = route.params.token as string;
      console.log(token);

      // loadInvestmentData();
      loadAllInvestmentData();
    });

    return {
      loading,
      error,
      finalize,
      investmentData,
      offeringCompany,
      formError,
      formErrorText,
      // computed
      unaccreditedError,
      // components
      investmentDetailsComponent,
      documentSignComponent,
      // methods
      investmentIsSaving,
      investmentDidSave,
      filesDidUpdate,
      documentSignInitialized,
      documentSigned,
      completeInvestment,
      formErrorEvent,
      closeFormError,
      redirectAccreditation,
    };
  },
});
