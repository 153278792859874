
import { defineComponent, inject, PropType, ref, computed } from "vue";
import { MakeInvestmentResponse } from "@/interfaces/v1/MakeInvestmentResponse";
import { Api } from "@/api";
import {
  PostComplianceReport,
  PostCompleteInvestment,
  PostConfirmUserLink,
  V1ResponseWrapper,
} from "@/api/services/v1Payloads";

export default defineComponent({
  name: "InvestNowInvestmentCompleteInvestment",
  props: {
    investmentData: {
      type: Object as PropType<MakeInvestmentResponse>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const $api = inject("$api") as Api;

    const processing = ref(false);

    // computed
    const offeringTitle = computed(() => {
      if (props.investmentData?.offering?.offering_title) {
        return props.investmentData.offering.offering_title;
      }

      return "-";
    });

    // ui methods
    const completeInvestment = async () => {
      console.log("completeInvestment");

      processing.value = true;

      // post compliance report
      try {
        const req: PostComplianceReport = {
          investor_id: props.investmentData.investors[0].investor_id,
          investor_type: props.investmentData.investors[0].investor_type,
        };

        await $api.v1.saveComplianceReport(req);

        console.log(req);
      } catch (e) {
        console.error(e);
        // todo: handle error ?
        return;
      }

      // post investment complete
      try {
        const req: PostCompleteInvestment = {
          user_id: props.investmentData.investors[0].user_id,
          investment_id: props.investmentData.investment.investment_id,
          investor_id: props.investmentData.investors[0].investor_id,
          offering_id: props.investmentData.investment.offering_id,
          signed_documents: true,
          reviewed_offering_docs:
            props.investmentData.offering_statuses.reviewed_offering_docs,
          entity_id: props.investmentData.offering_statuses.entity_id,
          downloaded_offering_docs_date:
            props.investmentData.offering_statuses
              .downloaded_offering_docs_date,
          downloaded_offering_docs:
            props.investmentData.offering_statuses.downloaded_offering_docs,
          signed_docs_date: null,
          reviewed_offering_docs_date:
            props.investmentData.offering_statuses.reviewed_offering_docs_date,
          complete_profile:
            props.investmentData.investors[0].form_complete === 100,
        };

        console.log(req);

        const { data }: { data: V1ResponseWrapper } =
          await $api.v1.completeInvestment(req);

        // todo: check for errors
        console.log(data);
      } catch (e) {
        console.error(e);

        // todo: handle error
        return;
      }

      try {
        const req: PostConfirmUserLink = {
          user_id: props.investmentData.offering_statuses.user_id,
          url: process.env.VUE_APP_CONFIRM_USER_LINK,
        };

        const { data }: { data: V1ResponseWrapper } =
          await $api.v1.postConfirmUserLink(req);

        console.log(data);
      } catch (e) {
        console.error(e);
        return;
      }

      processing.value = false;

      emit("complete");
    };

    return {
      // refs
      processing,
      //computed
      offeringTitle,
      // ui methods
      completeInvestment,
    };
  },
});
